analysis1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0;
}

.analysis1-text {
    flex: 1;
    padding: 20px;
}

.analysis1-text span {
    cursor: pointer;
    text-decoration: underline;
}

.analysis1-data {
    flex: 1;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.analysis1-data h3 {
    margin-top: 0;
}

.analysis1-data p {
    margin-bottom: 20px;
}

.analysis1-data strong {
    font-weight: bold;
}

.container {
    display: flex;
}

.text-content {
    flex: 1;
}

.quote-card {
    background-color: #f0f0f0;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 16px;
    margin-left: 20px;
    width: 300px;
}

.highlighted-word {
    background-color: yellow;
    cursor: pointer;
}

.example-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }

.text-area-wrapper {
    max-width: 1200px;
}

.quote-cards-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}


p {
    flex: 1;
}

.quote-cards {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
}

.quote-card {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 300px;
}

.quote-card.selected {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}

.left-justified {
    text-align: left;
    line-height: 1;
    flex-direction: column;
}

.line-container {
    display: block;
    margin-bottom: 1em;
}