.login-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 8px;
  }
  
  h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .input-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    padding: 0.75rem;
    background-color: #3498db;
    color: #ffffff;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
}

button:hover {
  background-color: #2980b9;
}

.nice-message {
  color: #27ae60;
  font-weight: bold;
  text-align: center;
  margin-top: 1.5rem;
}
