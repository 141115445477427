.content {
  padding: 20px;
}

.analysis-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.analysis-table th,
.analysis-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.analysis-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

.analysis-table tbody tr.highlight-row {
  background-color: #f9f9f9;
}

.analysis-table tbody tr:hover {
  background-color: #e6e6e6;
}

.analysis-table a {
  color: #4c8bf5;
  text-decoration: none;
}

.analysis-table a:hover {
  text-decoration: underline;
}

.analysis-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
