.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  
  .menu-icon {
    cursor: pointer;
  }
  
  nav {
    display: none;
  }
  
  .nav-open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #f5f5f5;
    padding: 2rem;
    z-index: 1;
  }
  
  .nav-closed {
    display: none;
  }
  
  nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  nav ul li {
    margin-bottom: 1rem;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #333;
    font-size: 1.1rem;
  }
  @media screen and (min-width: 768px) {
    .menu-icon {
      display: none;
    }
  
    nav {
      position: static;
      display: flex;
      background-color: transparent;
      width: auto;
      height: auto;
    }
  
    .nav-open {
      display: flex;
    }
  
    nav ul {
      display: flex;
    }
  
    nav ul li {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
   