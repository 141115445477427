.hero-section,
.cta-section {
  background-color: #4c8bf5;
  color: #fff;
  padding: 100px 0;
  text-align: center;
}

.feature-section,
.use-case-section,
.team-section {
  padding: 80px 0;
  text-align: center;
}

.feature-container,
.use-case-container,
.team-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 40px;
}

.feature-card,
.use-case-card,
.team-member {
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.team-member h3,
.team-member p {
  margin: 10px 0;
}

.cta-btn {
  display: inline-block;
  background-color: #fff;
  color: #4c8bf5;
  border-radius: 25px;
  padding: 12px 25px;
  margin-top: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.cta-btn:hover {
  background-color: #3c7ac7;
  color: #fff;
}

.h-yellow {
  background-color: yellow;
  color: black;
}

.no-underline {
  text-decoration: none;
  color: inherit;
}
